import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import VerifyCertificate from 'views/pages/verifyCertificate';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication/Login')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: 'login',
            children: [
                {
                    path: '/login',
                    element: <AuthLogin />
                }
            ]
        },
        {
            path: 'certificado',
            children: [
                {
                    path: 'verify',
                    children: [
                        {
                            path: ':correlativo_certificado',
                            element: <VerifyCertificate />
                        }
                    ]
                }
            ]
        }
    ]
};

export default AuthenticationRoutes;

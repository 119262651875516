import React, { useState } from 'react';
import { useQuery } from 'react-query';
import LoadingSpinner from 'components/loadingSpinner';
import { getSessionCheck } from 'api';
import ErrorScreen from 'views/pages/errorScreen';
import { useLocation } from "react-router-dom"
import { useEffect } from 'react';
import { routesAccess } from 'utils/helpers';
import { useUser } from 'contexts/UserContext';


const withAuth = (WrappedComponent) => {
    const AuthenticatedComponent = (props) => {
        const sampleLocation = useLocation();
        const [progress, setProgress] = useState(0);
        const [currentURL, setCurrentURL] = useState("");
        const { setRole, setNameUser, setCertNum } = useUser();

        useEffect(() => {
            setCurrentURL(sampleLocation.pathname);
        }, [sampleLocation]);
        const { isLoading, isError, data, error } = useQuery('checksession', () => {
            return getSessionCheck().then((response) => {
                if (response?.data?.success) {
                    sessionStorage.setItem('token', response?.data?.token); // Guarda el token de autenticación en el almacenamiento local
                    setRole(response?.data.user_role);
                    setNameUser(response?.data.user_email);
                    setCertNum(response?.data.max_capacitaciones);
                    return response?.data;
                }
                throw response;
            });

        }, {
            onDownloadProgress: (progressEvent) => {
                setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            },
            retry: 0,
            throwOnError: true,
        });

        if (isLoading) {
            return <LoadingSpinner progress={progress} />;
        }

        if (isError || !data) {
            sessionStorage.clear();
            return <ErrorScreen error={error} toLogin={true} />;
        }
        const isCertificadosAccessible = routesAccess.some(route => route.path.includes(currentURL) && route.access.includes(data?.user_role));
        if (!isCertificadosAccessible) {
            console.log("debería disparar el error");
            const otherError = { data: { code: "role_error", message: "No está autorizado para realizar esta acción", data: { status: "403" } } }
            return <ErrorScreen error={otherError} toLogin={true} />;
        }

        return <WrappedComponent {...props} />;
    };

    return AuthenticatedComponent;
};

export default withAuth;
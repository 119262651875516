import React from 'react';
import { CircularProgress, Box, LinearProgress } from '@mui/material';

function LoadingSpinner({ text, progress }) {
  return (
    <Box sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center"
  }} display="flex" alignItems="center" flexDirection="column">
      <CircularProgress />
      {text && <Box my={2}>{text}</Box>}
      {typeof progress === 'number' && (
        <Box width={200} mt={2}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </Box>
  );
}

export default LoadingSpinner;
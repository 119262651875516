// material-ui
import { Typography, Stack } from '@mui/material';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2">
            Sel-Otec
        </Typography>
        <Typography variant="subtitle2">
            Copyright © 2023 Sel-Otec
        </Typography>
    </Stack>
);

export default AuthFooter;

import axios from 'axios';

const API_BASE_URL = 'https://servicios-sel-otec.cl/';
const getToken = () => {
    return sessionStorage.getItem('token');
};

export const getCertificados = ( param = "" ) => {
    return axios.get(`${API_BASE_URL}wp-json/operator/v1/certificates${param}`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    });
};

export const getCursos = () => {
    return axios.get(`${API_BASE_URL}wp-json/operator/v1/courses`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    });
}
export const getEmpresas = () => {
    return axios.get(`${API_BASE_URL}wp-json/operator/v1/users`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    });
}
export const postLogin = async (payload) => {
    const options = {
        method: 'post',
        url: `${API_BASE_URL}wp-json/operator/v1/login`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: payload
    };
    const { data } = await axios(options);
    return data;
};
export const postCertificado = (data) => {
    const options = {
        method: 'post',
        url: `${API_BASE_URL}wp-json/operator/v1/certificates`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        },
        data
    };
    return axios(options)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error?.response;
        });
};
export const putCertificado = (data) => {
    const options = {
        method: 'put',
        url: `${API_BASE_URL}wp-json/operator/v1/certificates/${data?.id_certificado}`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        },
        data
    };
    return axios(options)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error?.response;
        });
};
export const deleteCertificado = (data) => {
    const options = {
        method: 'put',
        url: `${API_BASE_URL}wp-json/operator/v1/certificates/${data?.id_certificado}/toggle`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        },
        data
    };
    return axios(options)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error?.response;
        });
};
export const getReallyCode = (id,code) => code ? code : id;
export const getCertificadoPdf = (data) => axios.get(`${API_BASE_URL}wp-json/operator/v1/certificate/pdf/${data?.correlativo_certificado}`, {
    responseType: 'blob'
});
export const postCargarExcel = (data) => {
    const formData = new FormData();
    // console.log("data.files[0]", data.files[0]);
    formData.append("file", data.file_xlsx[0]);
    const options = {
        method: 'post',
        url: `${API_BASE_URL}wp-json/operator/v1/certificates/upload`,
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${getToken()}`
        }
    };
    return axios(options)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error?.response;
        });
};
export const getSessionCheck = async () => {
    const options = {
        method: 'get',
        url: `${API_BASE_URL}wp-json/operator/v1/session/check`,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getToken()}`
        }
    };

    return await axios(options)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error?.response;
        });;
};

export const getVerificateCertificate = async (correlativo_certificado) => {
    const options = {
        method: 'get',
        url: `${API_BASE_URL}wp-json/operator/v1/certificates/${correlativo_certificado}`,
        headers: {
            'Content-Type': 'application/json'
        }
    };

    return await axios(options)
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error?.response;
        });;
};
// assets
import { IconPlus, IconCheckupList, IconClipboardList } from '@tabler/icons';
import { roles } from '../utils/helpers';
// constant
const icons = {
    IconPlus, IconCheckupList, IconClipboardList
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'Opciones',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'servicios',
            title: 'Servicios',
            type: 'collapse',
            icon: icons.IconPlus,
            children: [
                {
                    id: 'certificados',
                    title: 'Listado de certificados',
                    type: 'item',
                    url: '/certificados',
                    icon: icons.IconCheckupList,
                    breadcrumbs: false,
                    access: [roles.EMPRE, roles.ADMIN]
                },
                {
                    id: 'gestio-certificados',
                    title: 'Gestionar certificados',
                    type: 'item',
                    url: '/certificados/gestion',
                    icon: icons.IconClipboardList,
                    breadcrumbs: false,
                    access: [roles.ADMIN]
                },
                {
                    id: 'cargar-certificados',
                    title: 'Cargar certificados',
                    type: 'item',
                    url: '/certificados/cargar',
                    icon: icons.IconClipboardList,
                    breadcrumbs: false,
                    access: [roles.ADMIN]
                }
            ]
        }
    ]
};
export default pages;

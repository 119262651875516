import { Link, Navigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import Wrapper from '../Wrapper';
import CardWrapper from '../CardWrapper';
import Logo from 'ui-component/Logo';
import AuthFooter from 'ui-component/cards/AuthFooter';
import { useEffect, useState } from 'react';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const ErrorScreen = ({ error, toLogin }) => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const { data: { code = "general", message = "Ha ocurrido un error desconocido!", data: { status = "N/A" } = {} } = {} } = error;
    const [goNavigate, setGoNavigate] = useState(false);
    const [countRedirect, setCountRedirect] = useState(10);
    useEffect(() => {
        let intervalId;
        if (toLogin) {
            intervalId = setInterval(() => {
                if (countRedirect <= 0) {
                    setGoNavigate(true);
                }

                setCountRedirect(countRedirect - 1);
            }, 1000);
        }
        return () => intervalId && clearInterval(intervalId);
    }, [toLogin, countRedirect, setGoNavigate]);
    return goNavigate ? <Navigate to="/login" replace /> : (
        <Wrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <CardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="login">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        ¡Vaya, algo salió mal!
                                                    </Typography>
                                                    <Typography
                                                        variant="caption"
                                                        fontSize="16px"
                                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                                    >
                                                        {message}
                                                    </Typography>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant='h6'
                                                    >
                                                        "{status}" {code}
                                                    </Typography>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                    </Grid> */}
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            <Typography
                                                variant="subtitle1"
                                            >
                                                Si el error persiste, póngase en contacto con el administrador.
                                            </Typography>
                                            {toLogin && (<Typography
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Serás redireccionado en: {countRedirect} segundos!
                                            </Typography>)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default ErrorScreen;

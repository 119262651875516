export const roles = {
    EMPRE: 'empresa',
    ADMIN: 'administrator'
}
export const routesAccess = [
    {
        path: '/login',
        access: []
    },
    {
        path: '/dashboard',
        access: [roles.EMPRE, roles.ADMIN]
    },
    {
        path: '/dashboard/default',
        access: [roles.EMPRE, roles.ADMIN]
    },
    {
        path: '/certificados',
        access: [roles.EMPRE, roles.ADMIN]
    },
    {
        path: '/certificados/gestion',
        access: [roles.ADMIN]
    },
    {
        path: '/certificados/cargar',
        access: [roles.ADMIN]
    }
]
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useQuery } from 'react-query';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    // const { isLoading, error, data } = useQuery('repoData', () =>
    //     fetch('https://api.github.com/repos/tannerlinsley/react-query').then(res =>
    //         res.json()
    //     )
    // );

    // if (isLoading) return 'Loading...';

    // if (error) return 'An error has occurred: ' + error.message;

    return useRoutes([AuthenticationRoutes, MainRoutes]);
}

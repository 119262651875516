import { Link, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid, Paper, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import Wrapper from '../Wrapper';
import CardWrapper from '../CardWrapper';
import Logo from 'ui-component/Logo';
import { useEffect, useState } from 'react';
import LoadingSpinner from 'components/loadingSpinner';
import { getVerificateCertificate } from 'api';
import ErrorScreen from '../errorScreen';

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const VerifyCertificate = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const [success, setSuccess] = useState(false);
    const [dataCertificate, setDataCertificate] = useState({});
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState({});
    const { correlativo_certificado } = useParams();
    useEffect(() => {
        getVerificateCertificate(correlativo_certificado).then(({ data }) => {
            if (data?.success) {
                setDataCertificate(data);
                setSuccess(data?.success);
            } else {
                setError({ data: { code: "certificate_not_found", message: "No se encontró ningún certificado válido.", data: { status: "404" } } });
                setIsError(true);
            }
        });
    }, [correlativo_certificado, setIsError]);

    const { fecha_culminacion, nombre_curso, codigo_certificado, nombres_estudiante, apellidos_estudiante, user_name } = dataCertificate;

    return isError ? <ErrorScreen error={error}></ErrorScreen> : (
        <Wrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <CardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <Link to="login">
                                            <Logo />
                                        </Link>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                {success ? (<Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        {"El certificado es válido!"}
                                                    </Typography>
                                                    <Paper>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="h5" component="h2" style={{ textAlign: 'center' }}>
                                                                    {nombre_curso}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1" color="textSecondary">
                                                                    Certificado: Reg. N° {codigo_certificado}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1" color="textSecondary">
                                                                    Nombre del estudiante: {nombres_estudiante} {apellidos_estudiante}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1" color="textSecondary">
                                                                    Fecha de culminación: {fecha_culminacion}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography variant="subtitle1" color="textSecondary">
                                                                    Empresa: {user_name}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Paper>
                                                </Stack>) : (
                                                    <>
                                                        <LoadingSpinner />
                                                        <br /><br />
                                                        <Typography variant="h6">Cargando información...</Typography>
                                                    </>)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default VerifyCertificate;

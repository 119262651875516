import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import withAuth from '../hocs/authentication/withAuth';
import { Navigate } from 'react-router';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// Sel-Otec
const Certificados = Loadable(lazy(() => import('views/certificados')));
const CertificadosGestion = Loadable(lazy(() => import('views/certificadosGestion')));
const CertificadosCargar = Loadable(lazy(() => import('views/certificadosCargar')));
// ==============================|| MAIN ROUTING ||============================== //

const MainLayoutWithAuth = withAuth(MainLayout);

const MainRoutes = {
    path: '/',
    element: <MainLayoutWithAuth />,
    children: [
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />
                }
            ]
        },
        {
            path: 'certificados',
            children: [
                {
                    path: '',
                    element: <Certificados />
                },
                {
                    path: 'gestion',
                    element: <CertificadosGestion />
                },
                {
                    path: 'cargar',
                    element: <CertificadosCargar />
                }
            ]
        },
        {
            path: '*',
            element: <Navigate to="/login" replace />
        },
    ]
};

export default MainRoutes;

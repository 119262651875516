import { createContext, useContext, useReducer } from "react";

const UserContext = createContext({
    name: "",
    email: "",
    role: "",
    cert_num: 0,
    setRole: () => { },
});

function userReducer(state, action) {
    switch (action.type) {
        case 'UPDATE_ROLE': {
            return { ...state, role: action.payload }
        }
        case 'UPDATE_NAME': {
            return { ...state, name: action.payload }
        }
        case 'UPDATE_CERTNUM': {
            return { ...state, cert_num: action.payload }
        }
        default: {
            return state;
        }
    }
}

function UserProvider({ children }) {
    const [state, dispatch] = useReducer(userReducer, {
        name: "",
        email: "",
        role: "",
        cert_num: 0
    });
    const setRole = (role) => dispatch({ type: 'UPDATE_ROLE', payload: role });
    const setNameUser = (role) => dispatch({ type: 'UPDATE_NAME', payload: role });
    const setCertNum = (role) => dispatch({ type: 'UPDATE_CERTNUM', payload: role });
    return <UserContext.Provider value={{ ...state, setRole, setNameUser, setCertNum }}>{children}</UserContext.Provider>
}

function useUser() {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context
}
export { UserProvider, useUser }

import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { UserProvider } from './contexts/UserContext';
// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools';

// ==============================|| APP ||============================== //

const queryClient = new QueryClient();
const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <QueryClientProvider client={queryClient}>
            <UserProvider>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={themes(customization)}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                            {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                        </NavigationScroll>
                    </ThemeProvider>
                </StyledEngineProvider>
            </UserProvider>
        </QueryClientProvider>
    );
};

export default App;
